import jQuery from '../../../../node_modules/jquery';
import './style.scss';
import '../../../../node_modules/slick-slider/slick/slick.min.js';
import Vivus from '../../../../node_modules/vivus/dist/vivus.min.js';
// import '../../../../node_modules/body-scroll-lock/lib/bodyScrollLock.min.js';

(function($) {
	$(document).ready(function() {
		var bodyScrollLock = require('../../../../node_modules/body-scroll-lock/lib/bodyScrollLock.min.js');
		var disableBodyScroll = bodyScrollLock.disableBodyScroll;
		var enableBodyScroll = bodyScrollLock.enableBodyScroll;
		// 2. Get a target element that you want to persist scrolling for (such as a modal/lightbox/flyout/nav).
		// Specifically, the target element is the one we would like to allow scroll on (NOT a parent of that element).
		// This is also the element to apply the CSS '-webkit-overflow-scrolling: touch;' if desired.
		var targetElement = document.querySelector('.mobile-overlay-menu');
		// Mobile Menu
		$('.menu-toggle, .mobile-menu-close').click(function() {
			$('.mobile-overlay-menu').toggleClass('shown');
			$('body').toggleClass('mobile-menu-shown');
			if ($('.mobile-overlay-menu').hasClass('shown')) {
				disableBodyScroll(targetElement);
			} else {
				enableBodyScroll(targetElement);
			}
		}); // End Mobile Menu

		// $('.main-navigation .menu-item-has-children').hover(
		// 	function() {
		// 		$(this).addClass('sub-menu-shown');
		// 		var subMenuID = $(this).attr('data-submenu-id');
		// 		$(this)
		// 			.find('#' + subMenuID)
		// 			.addClass('shown');
		// 	},
		// 	function() {
		// 		$(this).removeClass('sub-menu-shown');
		// 		var subMenuID = $(this).attr('data-submenu-id');
		// 		$(this)
		// 			.find('#' + subMenuID)
		// 			.removeClass('shown');
		// 	}
		// );
		// Header Arrow Scroll
		if ($('.arrow-down-scroll').length) {
			$('.arrow-down-scroll').click(function() {
				$('html, body').animate(
					{
						scrollTop:
							$(this)
								.parent()
								.next()
								.offset().top - 80
					},
					1500
				);
			});
		}
		// // Sliders
		if ($('.carousel').length) {
			if ($('.carousel').hasClass('featured-posts-slider')) {
				var numSlick = 0;
				$('.carousel').each(function() {
					numSlick++;
					$(this)
						.addClass('carousel-' + numSlick)
						.slick({
							dots: false,
							arrows: true,
							infinite: true,
							slidesToScroll: 1,
							centerMode: true,
							variableWidth: true,
							responsive: [
								{
									breakpoint: 768,
									settings: {
										variableWidth: false,
										centerMode: false,
										slidesToShow: 1
									}
								}
							]
						});
				});
			} else if (!$('.carousel').hasClass('carousel-with-thumbnails')) {
				var numSlick = 0;
				$('.carousel').each(function() {
					numSlick++;
					$(this)
						.addClass('carousel-' + numSlick)
						.slick({
							dots: true,
							arrows: false,
							autoplay: true,
							autoplaySpeed: 8000
						});
				});
			} else {
				// $('.carousel').slick({
				// 	dots: false,
				// 	arrows: false,
				// 	asNavFor: '.carousel-thumbnails'
				// });
				// $('.carousel-thumbnails').slick({
				// 	slidesToShow: 4,
				// 	slidesToScroll: 1,
				// 	dots: false,
				// 	arrows: true,
				// 	focusOnSelect: true,
				// 	asNavFor: '.carousel'
				// });
				var numSlick = 0;
				$('.carousel-with-thumbnails').each(function() {
					numSlick++;
					$(this)
						.addClass('carousel-with-thumbnails-' + numSlick)
						.slick({
							asNavFor: '.carousel-thumbnails-' + numSlick,
							dots: false,
							arrows: false
						});
				});

				numSlick = 0;
				$('.carousel-thumbnails').each(function() {
					numSlick++;
					$(this)
						.addClass('carousel-thumbnails-' + numSlick)
						.slick({
							asNavFor: '.carousel-with-thumbnails-' + numSlick,
							slidesToShow: 4,
							slidesToScroll: 1,
							dots: false,
							arrows: true,
							focusOnSelect: true
						});
				});
			}
		}
		// End Sliders

		if ($('.svg-animation-image').length) {
			$('.svg-animation-image svg').each(function(id, elem) {
				var svgVivus = new Vivus(
					elem,
					{
						duration: 500,
						start: 'inViewport'
					},
					myCallback
				);
			});
			function myCallback() {}
			$(window).on('scroll', function() {
				var scroll = $(window).scrollTop();
				$('.block-impact .item-image').each(function(id, elem) {
					var imageOffset = $(this).offset().top;
					var distance = Math.ceil(imageOffset - scroll, 90);
					$(this).css({
						transform: 'translateY(' + distance * 0.05 + 'px)'
					});
				});
			});
		}

		if ($('.accordion-item').length) {
			$('.accordion-header').click(function(e) {
				if (
					$(this)
						.parent()
						.hasClass('expanded') === false
				) {
					var $accordionGroup = $(this)
						.parent()
						.parent();

					$accordionGroup
						.find('.accordion-item.expanded .accordion-content')
						.slideUp();
					$accordionGroup
						.find('.accordion-item.expanded')
						.removeClass('expanded');
					$(this)
						.next()
						.slideDown();
					$(this)
						.parent()
						.addClass('expanded');
				} else {
					var $accordionGroup = $(this)
						.parent()
						.parent();

					$accordionGroup
						.find('.accordion-item.expanded .accordion-content')
						.slideUp();
					$accordionGroup
						.find('.accordion-item.expanded')
						.removeClass('expanded');
					$(this)
						.next()
						.slideUp();
					$(this)
						.parent()
						.removeclass('expanded');
				}
			});
		}
		if ($('.tab-item').length) {
			var tabId = 0;
			$('.tab-label').click(function(e) {
				if ($(this).hasClass('shown') === false) {
					var newTabId = $(this).attr('data-tab-id');

					var $tabGroup = $(this)
						.parent()
						.parent();

					$tabGroup
						.find('.tab-item.shown')
						.removeClass('shown load-shown animation-reverse');

					$tabGroup
						.find('.tab-label.shown')
						.removeClass('shown bg-primary load-shown');

					$tabGroup.find('.tab-id-' + newTabId).addClass('shown');
					if (newTabId > tabId) {
						$tabGroup
							.find('.tab-id-' + newTabId)
							.addClass('animation-reverse');
					}
					$(this).addClass('shown bg-primary');
					tabId = newTabId;
				}
			});
			$('.mobile-tab-labels').change(function(e) {
				var newTabId = $(this).val();

				var $tabGroup = $(this)
					.parent()
					.parent();

				$tabGroup
					.find('.tab-item.shown')
					.removeClass('shown load-shown animation-reverse');

				$tabGroup.find('.tab-id-' + newTabId).addClass('shown');
				if (newTabId > tabId) {
					$('.tab-id-' + newTabId).addClass('animation-reverse');
				}
				$(this).addClass('shown bg-primary');
				tabId = newTabId;
			});
		}
		if ($('.gallery-item, .block-product-gallery').length) {
			var $currentGalleryItem;
			$('.gallery-item, .gallery-item-slide').click(function(e) {
				$currentGalleryItem = $(this);
				if ($currentGalleryItem.hasClass('slide')) {
					var $galleryGroup = $('.block-product-gallery');
				} else {
					var $galleryGroup = $currentGalleryItem
						.parent()
						.parent()
						.parent();
				}
				setGalleryLightboxContent();
				$galleryGroup.find('.lightbox').fadeIn();
			});

			$('.lightbox-next').click(function() {
				if ($currentGalleryItem.hasClass('slide')) {
					if (
						$currentGalleryItem
							.parent()
							.parent()
							.next().length
					) {
						$currentGalleryItem = $currentGalleryItem
							.parent()
							.parent()
							.next()
							.find('.slide');
					} else {
						$currentGalleryItem = $currentGalleryItem
							.parent()
							.parent()
							.siblings()
							.first()
							.find('.slide');
					}
				} else {
					if ($currentGalleryItem.next().length) {
						$currentGalleryItem = $currentGalleryItem.next();
					} else {
						$currentGalleryItem = $currentGalleryItem
							.siblings()
							.first();
					}
				}
				setGalleryLightboxContent();
			});
			$('.lightbox-prev').click(function() {
				if ($currentGalleryItem.hasClass('slide')) {
					if (
						$currentGalleryItem
							.parent()
							.parent()
							.prev().length
					) {
						$currentGalleryItem = $currentGalleryItem
							.parent()
							.parent()
							.prev()
							.find('.slide');
					} else {
						$currentGalleryItem = $currentGalleryItem
							.parent()
							.parent()
							.siblings()
							.last()
							.find('.slide');
					}
				} else {
					if ($currentGalleryItem.prev().length) {
						$currentGalleryItem = $currentGalleryItem.prev();
					} else {
						$currentGalleryItem = $currentGalleryItem
							.siblings()
							.last();
					}
				}
				setGalleryLightboxContent();
			});

			function setGalleryLightboxContent() {
				if ($currentGalleryItem.hasClass('slide')) {
					var $galleryGroup = $('.block-product-gallery');
					var image = $currentGalleryItem.css('background-image');
					var caption = $currentGalleryItem.attr('data-caption');
				} else {
					var $galleryGroup = $currentGalleryItem
						.parent()
						.parent()
						.parent();
					var image = $currentGalleryItem
						.find('.gallery-image')
						.css('background-image');
					var caption = $currentGalleryItem
						.find('.gallery-image')
						.attr('data-caption');
				}
				$galleryGroup
					.find('.lightbox .lightbox-image')
					.css({ 'background-image': image });
				$galleryGroup.find('.lightbox-caption').remove();

				if (caption) {
					$galleryGroup
						.find('.lightbox .lightbox-inner .lightbox-image-wrap')
						.append(
							'<div class="lightbox-caption">' +
								caption +
								'</div>'
						);
				}
			}

			$('.lightbox-close, .lightbox-backdrop').click(function(e) {
				var $galleryGroup = $(this)
					.parent()
					.parent()
					.parent();

				$galleryGroup.find('.lightbox').fadeOut();
			});
		}
		if ($('.block-tolerance-calculator').length) {
			$('.tolerance-calculator-form').submit(function(e) {
				e.preventDefault();
				var QG = $(this)
						.find('#tolerance-QG')
						.val(),
					RPM = $(this)
						.find('#tolerance-RPM')
						.val(),
					KG = $(this)
						.find('#tolerance-KG')
						.val(),
					result = returnTolerance(QG, KG, RPM),
					resultContainer = $(this).find('#tolerance-result'),
					errorContainer = $(this).find('#tolerance-error');
				resultContainer.parent().removeClass('show-tolerance');
				errorContainer.parent().removeClass('show-tolerance');
				if (result.error === true) {
					errorContainer.parent().addClass('show-tolerance');
					errorContainer.html(result.res);
				} else {
					resultContainer.parent().addClass('show-tolerance');
					resultContainer.html(result.res);
				}
				return false;
			});
			function returnTolerance(QG, KG, RPM) {
				var values = [QG, KG, RPM],
					equation = (QG * KG * 9549) / RPM,
					result = {};
				function formatNumber(num) {
					var newNum = num;
					if (num >= 5) {
						newNum = Math.round(newNum);
					} else if (num > 1) {
						newNum.toFixed(2);
					} else {
						//Do Nothing
					}
					return newNum.toLocaleString() + ' gmm';
				}
				if (RPM === 0) {
					result.error = true;
					result.res = 'RPM can not be zero (0)';
				} else {
					result.error = false;
					result.res =
						'Result: <strong>' +
						formatNumber(equation) +
						'</strong>';
				}
				return result;
			}
		}
		if ($('.timeline').length) {
			var decades = [2010, 2000, 1990, 1980, 1950];
			var decadeOffsets = [];
			decades.forEach(function(value, index) {
				if ($('#timeline-' + value).length) {
					decadeOffsets[index] = $('#timeline-' + value).offset().top;
				}
			});
			$(window).scroll(function() {
				if ($(window).scrollTop() > 300) {
					$('.timeline-navigation').addClass('scrolled-timeline-nav');
				} else {
					$('.timeline-navigation').removeClass(
						'scrolled-timeline-nav'
					);
				}
				var scroll = Math.max($(window).scrollTop() - 100, 0);
				decades.forEach(function(value, index) {
					if (scroll <= decadeOffsets[0]) {
						$('.timeline-scroll-link .bg-primary').removeClass(
							'bg-primary'
						);
						$('.timeline-scroll-link-2010 .circle').addClass(
							'bg-primary'
						);
					} else if (
						scroll > decadeOffsets[Math.max(index - 1, 0)] - 60 &&
						scroll <= decadeOffsets[index] - 60
					) {
						$('.timeline-scroll-link .bg-primary')
							.removeClass('bg-primary')
							.addClass('bg-gray');
						$(
							'.timeline-scroll-link-' + value + ' .circle'
						).addClass('bg-primary');
					}
				});
			});

			$(document).on('click', 'a[href^="#"]', function(event) {
				event.preventDefault();

				$('html, body').animate(
					{
						scrollTop: $($.attr(this, 'href')).offset().top
					},
					400
				);
			});
		}

		var mapAnimation = document.getElementById('map-animation');
		if (mapAnimation) {
			var locationsContainer = document.getElementById('map-locations');
			var locations = mapAnimation.querySelectorAll('.map-location');

			//Inititaes the whole process
			(function loop() {
				var rand = Math.round(Math.random() * (1000 - 500)) + 500;
				setTimeout(function() {
					doAnimate();
					loop();
				}, rand);
			})();

			function doAnimate() {
				var maxIndex = locations.length - 1;
				var randIndex = Math.round(Math.random() * maxIndex);

				var locationClone = locations[randIndex].cloneNode(true);
				locationClone.classList.add('location-blip');
				locationClone.setAttribute('style', 'opacity: 1;');
				locationsContainer.appendChild(locationClone);

				var radius = locationClone.getAttribute('r'); //3.5 go up to 16.5
				var radiusIncrement = 0.1;
				var opacity = 1;
				var locationAnimate = setInterval(function() {
					if (locationClone.style.opacity <= 0) {
						clearInterval(locationAnimate);
						locationsContainer.removeChild(locationClone);
					} else {
						var newRadius =
							parseFloat(radius) + parseFloat(radiusIncrement);
						radius = newRadius.toFixed(3);
						locationClone.setAttribute('r', radius);

						opacity = opacity - 0.009;
						locationClone.style.opacity = opacity;
					}
				}, 1000 / 60);
			}
		}

		if ($('.block-agents-locations').length) {
			$('.locations-filter-region').change(function() {
				var $block = $(this)
					.parent()
					.parent()
					.parent()
					.parent();
				getTerritories($block);
				getLocations($block);
			});
			$('.locations-filter-territory').change(function() {
				var $block = $(this)
					.parent()
					.parent()
					.parent()
					.parent();
				getLocations($block);
			});
			function getTerritories($block) {
				var region = $block.find('.locations-filter-region').val();

				$block.find('.locations-filter-territory').val('');
				$.ajax({
					type: 'POST',
					url: localized.ajaxUrl,
					dataType: 'html', // add data type
					data: {
						action: 'get_ajax_location_territories',
						region: region
					},
					success: function(response) {
						$block
							.find('.locations-filter-territory')
							.html(response);
					}
				});
			}
			function getLocations($block) {
				var region = $block.find('.locations-filter-region').val();
				var territory = $block
					.find('.locations-filter-territory')
					.val();
				var postType = $block.find('.agents-locations-post-type').val();
				$.ajax({
					type: 'POST',
					url: localized.ajaxUrl,
					dataType: 'html', // add data type
					data: {
						action: 'get_ajax_locations',
						region: region,
						territory: territory,
						postType: postType
					},
					success: function(response) {
						$block.find('.location-items').html(response);
					}
				});
			}
		}

		if ($('.filter.brand-industry').length) {
			$('.filter.brand-industry').change(function() {
				if ($(this).val().length) {
					$('.filter.brand-region').val('');

					getFilteredBrands();
				} else {
					$('.brand-item').fadeIn();
					$('.filter.brand-industry').val('');
				}
			});
			$('.filter.brand-region').change(function() {
				if ($(this).val().length) {
					$('.filter.brand-industry').val('');

					getFilteredBrands();
				} else {
					$('.brand-item').fadeIn();
					$('.filter.brand-region').val('');
				}
			});
			function getFilteredBrands() {
				$('.brand-item').fadeOut();
				var region = $('.filter.brand-region').val();
				var industry = $('.filter.brand-industry').val();
				var queryClass = '.brand-item';
				var unqueryClass = '.brand-item:not(';
				if (region.length) {
					queryClass += '.brand-region-id-' + region;
					unqueryClass += '.brand-region-id-' + region;
				}
				if (industry.length) {
					queryClass += '.brand-industry-id-' + industry;
					unqueryClass += '.brand-industry-id-' + industry;
				}
				unqueryClass += ')';
				$(queryClass).fadeIn();
				// $(unqueryClass).fadeOut();
			}
			// function getBrandIndustries() {
			// 	var region = $('.filter.brand-region').val();

			// 	$('.filter.brand-industry').val('');
			// 	$.ajax({
			// 		type: 'POST',
			// 		url: localized.ajaxUrl,
			// 		dataType: 'html', // add data type
			// 		data: {
			// 			action: 'get_ajax_brand_industries',
			// 			region: region
			// 		},
			// 		success: function(response) {
			// 			$('.filter.brand-industry').html(response);
			// 		}
			// 	});
			// }
		}
		if ($('.filter.product-industry').length) {
			if ($('.filter.product-industry').val().length) {
				if (
					!$(
						'.product-item.product-industry-id-' +
							$('.filter.product-industry').val()
					).length
				) {
					$('.products-no-items').fadeIn('slow');
				} else {
					$('.products-no-items').hide();
				}
				$(
					'.product-item.product-industry-id-' +
						$('.filter.product-industry').val()
				).fadeIn();
				$(
					'.product-item:not(.product-industry-id-' +
						$('.filter.product-industry').val() +
						')'
				).fadeOut('fast');
			}
			$('.filter.product-industry').change(function() {
				if ($(this).val().length) {
					if (
						!$('.product-item.product-industry-id-' + $(this).val())
							.length
					) {
						$('.products-no-items').fadeIn('slow');
					} else {
						$('.products-no-items').hide();
					}
					$(
						'.product-item.product-industry-id-' + $(this).val()
					).fadeIn();
					$(
						'.product-item:not(.product-industry-id-' +
							$(this).val() +
							')'
					).fadeOut('fast');
				} else {
					$('.products-no-items').hide();
					$('.product-item').fadeIn();
				}
			});
		}
		// // Dropdown Menu
		$('.mobile-overlay-menu .menu > .menu-item-has-children > a').click(
			function(e) {
				e.preventDefault();
				var $target = $(this).parent();
				$('.mobile-overlay-menu .expanded > .sub-menu')
					.not($target.find('> .sub-menu'))
					.slideUp();
				$('.mobile-overlay-menu .menu-item-has-children.expanded')
					.not($target)
					.removeClass('expanded');
				$target.toggleClass('expanded');
				$target.find('> .sub-menu').slideToggle();
			}
		);
		// if ($('.main-nav .sub-menu').length) {
		// 	$('.main-nav .menu-item-has-children, .main-nav .sub-menu').hover(
		// 		function() {
		// 			// $('.search-shown').removeClass('search-shown');
		// 			$(this).addClass('sub-menu-shown');

		// 			// $('.sub-menu-shown')
		// 			// 	.not($(this).parent())
		// 			// 	.not(
		// 			// 		$(this)
		// 			// 			.parent()
		// 			// 			.parent()
		// 			// 			.parent()
		// 			// 	)
		// 			// 	.removeClass('sub-menu-shown');
		// 		},
		// 		function() {
		// 			$('.sub-menu-shown').removeClass('sub-menu-shown');
		// 		}
		// 	);
		// 	$('html').click(function() {
		// 		if ($('.sub-menu-shown').length) {
		// 			$('.sub-menu-shown').removeClass('sub-menu-shown');
		// 		}
		// 	});
		// }
		if ($('.search-wrap').length) {
			$('.search-toggle').click(function(e) {
				e.stopPropagation();
				e.preventDefault();
				$('.sub-menu-shown').removeClass('sub-menu-shown');

				$(this)
					.parent()
					.toggleClass('search-shown');

				$('#search').focus();
			});
			$('.search-form').click(function(e) {
				e.stopPropagation();
				// e.preventDefault();
			});
			$('html').click(function() {
				if ($('.search-shown').length) {
					$('.search-shown').removeClass('search-shown');
				}
			});
		}
		if ($('.cookie-policy').length) {
			/////////////////////////////////////////////////
			// Standard Cookie Functions
			// Create
			function createCookie(name, value, days) {
				if (days) {
					var date = new Date();
					date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
					var expires = '; expires=' + date.toGMTString();
				} else var expires = '';
				document.cookie =
					escape(name) + '=' + escape(value) + expires + '; path=/';
			}
			// Read
			function readCookie(name) {
				var nameEQ = escape(name) + '=';
				var ca = document.cookie.split(';');
				for (var i = 0; i < ca.length; i++) {
					var c = ca[i];
					while (c.charAt(0) == ' ') c = c.substring(1, c.length);
					if (c.indexOf(nameEQ) == 0)
						return unescape(c.substring(nameEQ.length, c.length));
				}
				return null;
			}
			// Erase
			function eraseCookie(name) {
				createCookie(name, '', -1);
			}
			//
			/////////////////////////////////////////////////
			// Check to see if the cookie has been set, if not switch on the policy bar
			if (readCookie('cookiepolicy') != 'true') {
				$('#cookie-policy').addClass('show');
			}
			// On click set the cookie and remove class to hide consent bar
			$('#cookie-policy-accept').click(function(e) {
				e.preventDefault();
				createCookie('cookiepolicy', 'true', 1000);
				$('#cookie-policy').removeClass('show');
			});
		}
	}); // End Ready
})(jQuery); // End $
